import React from 'react';
import { Flex, Text, Image, Heading, Box } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import Step1Icon from '../assets/steps/Step1Icon.png';
import Step2Icon from '../assets/steps/Step2Icon.png';
import Step3Icon from '../assets/steps/Step3Icon.png';
import Step4Icon from '../assets/steps/Step4Icon.png';
import Wave1 from '../assets/Wave1';
import Wave2 from '../assets/Wave2';

import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';
import HowItWorksItem from '../zeta/HowItWorksItem';

const HowItWorks = ({ variant }) => {
  const data = {};
  const JointHowItWorks1 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks1.svg`;
  const PersonalHowItWorks2 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-howItWorks2.svg`;
  const JointHowItWorks3 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks3.svg`;

  if (variant === 'joint-accounts') {
    data.steps = [
      'Tell us about you and your relationship',
      'Invite your partner so they can confirm their info.',
      'You each get your own Zeta Joint Card.',
      'Start spending and saving, as a team!',
    ];

    data.images = [
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step1.png`,
      Step2Icon,
      Step3Icon,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step4.png`,
    ];
  } else if (variant === 'personal-accounts') {
    data.steps = [
      'Open your Zeta joint account',
      'Add on a personal account from your team drop down',
      'You can request a free personal debit card',
      'Start spending & saving privately',
    ];
  } else if (variant === 'solo-pass') {
    data.steps = [
      `Sign up using your Solo Pass code`,
      'Open your personal account in minutes',
      'You can request a free personal debit card',
      'Start spending & saving',
    ];
  }

  if (variant === 'solo-pass' || variant === 'personal-accounts') {
    data.images = [
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks1.svg`,
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks2.svg`,
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks3.svg`,
    ];
  }

  return (
    <Flex py={[10, 24, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[10, 10, 16]}
        px={[4, 4, 0]}
      >
        <H2>How it works</H2>
        <H4 mt={4} center>
          Ready to get started? Here's what to expect.
        </H4>
      </Flex>

      <Flex
        align="flex-start"
        justify={['center', 'center', 'center', 'flex-end']}
        direction={['column', 'column', 'column', 'row']}
        marginLeft={[0, 0, 0, '5%']}
        px={[4, 4, 4, 0]}
      >
        <HowItWorksItem
          image={JointHowItWorks1}
          title="Open Joint Account"
          body="You and your +1 open an account in a quick online process"
          connector="even"
        />

        <HowItWorksItem
          image={PersonalHowItWorks2}
          title="Fund your account"
          body="Fund your Zeta Account to unlock your free personal account"
          connector="odd"
        />

        <HowItWorksItem
          image={JointHowItWorks3}
          title="Spend & save privately"
          body="Save separately in a personal account, but instantly transfer funds to other accounts"
          connector="none"
        />
      </Flex>

      <Flex justify="center" pt={['48px', '48px', '72px']}>
        <Box mt={8} width={['320px']} borderWidth="1px" borderRadius="20px">
          <Box padding="24px">
            <Text fontSize="25px" fontWeight="600" pb="12px">
              Don't have a partner?
            </Text>
            <Text
              fontWeight={theme.fontWeights.normal}
              color={colors.boulder}
              paddingRight={[0, 0, '5%']}
            >
              Get an existing Zeta member to share a Solo Pass from their account overview
              to sign up for a personal account without the need to open a joint account.
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
